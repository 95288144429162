import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Box from '@material-ui/core/Box';

class ALLBlogRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    return (
      <>
        <Grid container spacing={1}>
          {
            posts &&
            posts.map(({ node: post }, index ) => (
            <Grid item xs={12} md={4} key={post.fields.slug}>
              {index < 1000? (
                <Card>
                  <CardActionArea href={post.fields.slug}>
                    <CardContent className="CardContent-root">
                      <Box mb={1} className="CardHeader">
                          {post.frontmatter.tags && post.frontmatter.tags.length ? (
                            <p className="CardItem-tag">
                              <span>{post.frontmatter.tags[0]}</span>
                            </p>
                          ) : null}
                          <p className="CardItem-date">
                            {post.frontmatter.date}
                          </p>
                      </Box>
                      <Box mb={1}>
                        <h2 className="CardItem-title">
                          {post.frontmatter.title}
                        </h2>
                      </Box>
                      <Box mb={1}>
                        <p className="CardItem-idea">
                          {post.frontmatter.catch}
                        </p>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
                ) : null
              }
            </Grid>
            ))
          }
        </Grid>
      </>
    )
  }
}

ALLBlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query ALLBlogRollQuery {
        allMarkdownRemark(
          limit: 1000
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: {
            templateKey:  { eq: "idea-post" }
          } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                catch
                tags
                templateKey
                date(formatString: "YYYY.MM.DD")
                featuredpost
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <ALLBlogRoll data={data} count={count} />}
  />
)
