import React from 'react'
import Helmet from 'react-helmet'
import Container from '@material-ui/core/Container'

import Layout from '../../components/Layout'
import AllBlogRoll from '../../components/AllBlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet titleTemplate="%s | アイデアマップ">
          <title>アイデア一覧</title>
          <meta
            name="description"
            content="aaaaaa"
          />
        </Helmet>
        <section className="Section">
          <Container>
            <div className="PageTitle-section">
              <h1 className="TextCenter">
                All ideas<span>すべてのアイデア</span>
              </h1>
            </div>
            <AllBlogRoll />
          </Container>
        </section>
      </Layout>
    )
  }
}
